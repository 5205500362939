"use client";

import axios from "axios";
import Cookies from "js-cookie";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function Catalog({ checked, setChecked }) {
  const [limitId, setLimitId] = useState([]);
  const [data, setData] = useState();
  const locale = useLocale();
  const token = Cookies.get("access_token");

  const limitChange = (index) => {
    setLimitId([...limitId, index]);
  };

  const removeLimit = (index) => {
    if (limitId.includes(index)) {
      setLimitId(
        limitId.filter(function (item) {
          return item !== index;
        }),
      );
    }
  };

  const toggleChecked = () => {
    setChecked(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      const catalogContainer = document.querySelector(".catalog");
      if (catalogContainer && !catalogContainer.contains(e.target)) {
        setChecked(false);
      }
    };
    if (checked) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [checked]);

  useEffect(() => {
    const headers = {
      Hostname: "localhost",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.NEXT_PUBLIC_API}/api/4c/category/all`, { headers })
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {});
  }, [token]);

  return (
    <div className={checked ? "catalog catalog_active" : "catalog"}>
      <div className="container">
        <div className="catalog_left">
          {data?.data.map((item) => (
            <div className="catalog_left_block" key={item.id} id={item.id}>
              <Link
                onClick={toggleChecked}
                className="main_link"
                href={`/${locale}/product/catalog/${item.id}`}
              >
                <Image
                  src={`${process.env.NEXT_PUBLIC_FILE}/api/v1/file/download/${item.iconId}`}
                  alt="svg"
                  width={24}
                  height={24}
                />
                <span>{item.name}</span>
              </Link>

              <div className="catalog_right">
                <div className="catalog_right_menu">
                  {item.children.map((children) => (
                    <div
                      className="catalog_right_block"
                      key={children.id}
                      onMouseLeave={() => removeLimit(children.id)}
                    >
                      <Link
                        onClick={toggleChecked}
                        href={`/${locale}/product/catalog/${children.id}`}
                      >
                        <h5>
                          <b>{children.name}</b>
                        </h5>
                      </Link>
                      <ul
                        className={
                          limitId.includes(children.id) ? "limit_active" : ""
                        }
                      >
                        {children.children &&
                          children.children.map((children) => (
                            <li key={children.id}>
                              <Link
                                onClick={toggleChecked}
                                href={`/${locale}/product/catalog/${children.id}`}
                              >
                                {children.name}
                              </Link>
                            </li>
                          ))}
                        {children.children.length > 5 ? (
                          <span
                            className="more"
                            onClick={() => limitChange(children.id)}
                          >
                            {t("more")} {children.children.length - 5}
                          </span>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
                {/* <div className="catalog_right_banner">
                  <Image
                    unoptimized
                    src={item.image}
                    alt={"catalog_banner"}
                    width={260}
                    height={450}
                  />
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
