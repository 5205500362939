export const languageOptions = [
  { code: "uz", name: "O’zbek" },
  { code: "ru", name: "Русский" },
  { code: "en", name: "English" },
];

export const menuItems = [
  { label: "Home", path: "#" },
  { label: "For men", path: "#" },
  { label: "Technologies", path: "#" },
  { label: "For women", path: "#" },
  { label: "Kitchen", path: "#" },
  { label: "Sport", path: "#" },
  { label: "Special", path: "#" },
];
