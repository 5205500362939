export default function CatalogSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 2.5C3.99238 2.5 2.5 3.99238 2.5 5.83333C2.5 7.67428 3.99238 9.16667 5.83333 9.16667C7.67428 9.16667 9.16667 7.67428 9.16667 5.83333C9.16667 3.99238 7.67428 2.5 5.83333 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 10.8333C3.99238 10.8333 2.5 12.3257 2.5 14.1667C2.5 16.0076 3.99238 17.5 5.83333 17.5C7.67428 17.5 9.16667 16.0076 9.16667 14.1667C9.16667 12.3257 7.67428 10.8333 5.83333 10.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.8333 14.1667C10.8333 13.7064 11.2064 13.3333 11.6667 13.3333H16.6667C17.1269 13.3333 17.5 13.7064 17.5 14.1667C17.5 14.6269 17.1269 15 16.6667 15H11.6667C11.2064 15 10.8333 14.6269 10.8333 14.1667Z"
        fill="currentColor"
      />
      <path
        d="M11.6667 5C11.2064 5 10.8333 5.3731 10.8333 5.83333C10.8333 6.29357 11.2064 6.66667 11.6667 6.66667H16.6667C17.1269 6.66667 17.5 6.29357 17.5 5.83333C17.5 5.3731 17.1269 5 16.6667 5H11.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}
