"use client";

import axios from "axios";
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [smsCode, setSmsCode] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [token, setToken] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [cardSms, setCardSms] = useState(false);
  const [time, setTime] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cardImage, setCardImage] = useState("CardImage2");
  const [refresh, setRefresh] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [question, setQuestion] = useState(false);
  const [session, setSession] = useState("");
  const [smsToken, setSmsToken] = useState("");

  useEffect(() => {
    setSession(
      `Kyuu|${window?.navigator.platform}|${window?.navigator.platform}|${
        Math.random().toString(20).substring(2, 14) +
        Math.random().toString(20).substring(2, 14)
      }|000.000.00.000`,
    );
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) =>
        setSession(
          `Kyuu|${window.navigator.platform}|${window.navigator.platform}|${
            Math.random().toString(20).substring(2, 14) +
            Math.random().toString(20).substring(2, 14)
          }|${data.ip}`,
        ),
      )
      .catch((error) => console.log(error));
  }, []);

  // useEffect(() => {
  //   const savedToken = localStorage.getItem("token");
  //   setToken(savedToken);
  //   if (savedToken) {
  //     const headers = {
  //       "x-access-token": savedToken,
  //       "Cache-Control": "no-cache",
  //     };
  //     axios
  //       .get(`/v1/me`, { headers })
  //       .then((response) => {
  //         // handle successful response
  //       })
  //       .catch((error) => {
  //         if (error.response?.status === 401) {
  //           localStorage.removeItem("token");
  //           setToken("");
  //         }
  //       });
  //   }
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        smsCode,
        setSmsCode,
        showLogin,
        setShowLogin,
        showUserInfo,
        setShowUserInfo,
        telephone,
        setTelephone,
        token,
        setToken,
        addCard,
        setAddCard,
        cardSms,
        setCardSms,
        cardNumber,
        setCardNumber,
        cardName,
        setCardName,
        expiry,
        setExpiry,
        cardImage,
        setCardImage,
        time,
        setTime,
        loadingModal,
        setLoadingModal,
        question,
        setQuestion,
        session,
        smsToken,
        setSmsToken,
        refresh,
        setRefresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
