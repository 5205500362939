import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/components/modals.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalProvider"] */ "/app/app/[locale]/globalProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/Gilroy-Black.ttf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-ExtraBold.ttf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Heavy.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-Thin.ttf\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../fonts/Gilroy-UltraLight.ttf\",\"weight\":\"100\",\"style\":\"normal\"}],\"variable\":\"--font-gilroy\"}],\"variableName\":\"gilroy\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/basket/basket.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/favorites/favorites.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/order/checkout/checkout.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/product/[productId]/product.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/product/category/catalog.scss");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/profile/profile.scss");
