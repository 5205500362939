import axios from "axios";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CloseSvg from "../../assets/frags/closeSvg";
import AuthContext from "../../context/authContext";
import ClickLoading from "../clickLoading";
import Bg from "./bg";

export default function UserInfo() {
  const [loading, setLoading] = useState(false);
  const { setShowUserInfo } = useContext(AuthContext);
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const onSubmit = async (e) => {
    setLoading(true);
    axios.post("").then(
      (response) => {
        if (response.data.status === 0) {
          setError(response.data.message);
          setLoading(false);
        } else if (response.data.status === 1) {
          setShowUserInfo(false);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <>
      <div className="main_modal">
        <h1>Почти готово</h1>

        <span className="main_modal_info">
          Ваш профиль почти готов, представьтесь
        </span>

        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="firstName">Имя</label>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: "Введение Имя" }}
            render={({ field: { ref, ...field } }) => (
              <input className="main_input" placeholder="Ваше имя" />
            )}
          />

          <label htmlFor="lastName">Фамилия</label>
          <Controller
            control={control}
            name="lastName"
            rules={{ required: "Введение фамилия" }}
            render={({ field: { ref, ...field } }) => (
              <input className="main_input" placeholder="Ваше фамилия" />
            )}
          />

          <button type="submit" className="main_modal_btn">
            Продолжить
          </button>
        </form>

        <button className="close" onClick={() => setShowUserInfo(false)}>
          <CloseSvg />
        </button>

        {loading && <ClickLoading />}
      </div>

      <Bg state={setShowUserInfo} />
    </>
  );
}
