import { useLocale } from "next-intl";
import Link from "next/link";
import React from "react";

const Category = ({ data }) => {
  const locale = useLocale();
  return (
    <li className="header_list_item">
      <Link
        href={`/${locale}/product/category/${data.params.items[0].id}`}
        prefetch={true}
      >
        {data.params.name}
      </Link>
    </li>
  );
};

export default Category;
