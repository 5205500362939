export default function Logo() {
	return (
		<svg
			width='133'
			height='32'
			viewBox='0 0 133 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.96 0H4.26667C1.91025 0 0 1.91025 0 4.26667V15.9568L4.52547 11.4314L4.52706 11.433L15.96 0ZM0 15.9569V27.7333C0 30.0897 1.91025 32 4.26667 32H16.0431L0 15.9569ZM25.0941 32H27.7333C30.0897 32 32 30.0897 32 27.7333V4.26667C32 1.91025 30.0897 0 27.7333 0H24.92L24.9655 0.0455028L9.05254 15.9584L25.0941 32Z'
				fill='white'
			/>
			<path
				d='M57.2242 15.3734L65.5069 28.2629H58.3666L51.1906 17.0562V28.2629H44.8V3.20001H51.1906V13.7264L58.0096 3.20001H65.1499L57.2242 15.3734Z'
				fill='white'
			/>
			<path
				d='M81.4249 3.20001H88.6009L80.1753 18.6316V28.2629H73.7847V18.7032L65.3591 3.20001H72.5351L76.9622 12.4733L81.4249 3.20001Z'
				fill='white'
			/>
			<path
				d='M89.979 20.028V3.20001H96.3696V19.4193C96.3696 21.5198 97.5359 22.5701 99.8684 22.5701C102.201 22.5701 103.367 21.5198 103.367 19.4193V3.20001H109.758V20.028C109.758 22.6536 108.829 24.778 106.973 26.4011C105.164 28.0004 102.796 28.8 99.8684 28.8C96.9408 28.8 94.5607 28.0004 92.7281 26.4011C90.8954 24.778 89.979 22.6536 89.979 20.028Z'
				fill='white'
			/>
			<path
				d='M113.199 20.028V3.20001H119.59V19.4193C119.59 21.5198 120.756 22.5701 123.088 22.5701C125.421 22.5701 126.587 21.5198 126.587 19.4193V3.20001H132.978V20.028C132.978 22.6536 132.05 24.778 130.193 26.4011C128.384 28.0004 126.016 28.8 123.088 28.8C120.161 28.8 117.781 28.0004 115.948 26.4011C114.115 24.778 113.199 22.6536 113.199 20.028Z'
				fill='white'
			/>
		</svg>
	)
}
