import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API}/api/`,
  timeout: 1000,
  headers: {
    Hostname: "localhost",
    "Content-Type": "application/json",
  },
});
