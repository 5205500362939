"use client";

import { useLocale } from "next-intl";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useContext, useEffect, useState } from "react";
import CatalogSvg from "../assets/frags/catalogSvg";
import Logo from "../assets/frags/logo";
import AuthContext from "../context/authContext";
import Catalog from "./catalog";
import Search from "./search/search";
import Cookies from "js-cookie";
import { languageOptions } from "@/constants";
import axios from "axios";
import { DynamicCollectionNav } from "./daynamicnav";

export default function Header() {
  const [scroll, setScroll] = useState(false);
  const [isCatalogOpen, setCatalogOpen] = useState(false);
  const { setShowLogin } = useContext(AuthContext);
  const pathname = usePathname() || "";
  const locale = useLocale();
  const [navbar, setNavbar] = useState();
  const token = Cookies.get("access_token");

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 500);
    window.addEventListener("scroll", handleScroll);

    const fetchProductData = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_API}/api/v1/client/home-page`,
          {
            headers: {
              Hostname: "localhost",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setNavbar(response.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    fetchProductData();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const sortdata = navbar?.data
    ?.filter((item) => item.section === "NAVBAR")
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

  const navbarsection = {
    NAVBAR: DynamicCollectionNav,
  };

  const toggleCatalog = () => setCatalogOpen((prev) => !prev);

  return (
    <>
      <header id="top" className={scroll ? "header_active" : ""}>
        <div className="header_top">
          <div className="container header_wrap">
            <div className="header_left">
              <Link href={`/${locale}`} className="header_logo">
                <Logo />
              </Link>
              <ul className="header_list">
                {sortdata?.map((item) => {
                  const NavComponent = navbarsection[item.section];
                  if (!NavComponent) return null;
                  return <NavComponent key={item.id} data={item} />;
                })}
              </ul>
            </div>

            <div className="header_right">
              <div className="language">
                {languageOptions.map((item) =>
                  item.code === locale ? (
                    <span
                      key={item.code}
                      className={`language_block ${item.code} hover_3`}
                    >
                      {item.name}
                    </span>
                  ) : null,
                )}
                <div className="language_select">
                  {languageOptions.map((item) => (
                    <Link
                      key={item.code}
                      href={`/${item.code + pathname.slice(3)}`}
                      className={`language_select_block ${
                        locale === item.code ? "none" : ""
                      } ${item.code} hover_3`}
                      prefetch={true}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>

              <button className="header_btn" onClick={() => setShowLogin(true)}>
                Войти
              </button>
            </div>
          </div>

          <Catalog checked={isCatalogOpen} setChecked={setCatalogOpen} />
        </div>

        <div className="header_bottom">
          <div className="container header_bottom_wrapper">
            <button
              className={`header_catalog_btn ${
                isCatalogOpen ? "header_catalog_active" : ""
              }`}
              onClick={toggleCatalog}
            >
              <CatalogSvg />
              Каталог
            </button>

            <Search />
            <ul className="header_bottom_list">
              <li>
                <Link href={`/${locale}/basket`} prefetch={true} />
              </li>
              <li className="like">
                <Link href={`/${locale}/favorites`} prefetch={true} />
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div
        className={isCatalogOpen ? "main_bg main_bg_active" : "main_bg"}
        onClick={toggleCatalog}
      ></div>
    </>
  );
}
