import React, { useEffect, useState } from "react";
import Image from "next/image";
import Cookies from "js-cookie";
import { getImageUrl } from "@/lib/functions";
import { useRouter } from "next/navigation";
import { useLocale } from "next-intl";

const Searchcard = ({ data, setSearch }) => {
  const token = Cookies.get("access_token");
  const [productDetails, setProductDetails] = useState(null);
  const link = useRouter();
  const locale = useLocale();

  const getIteam = () => {
    setSearch("");
    link.push(`/${locale}/product/${productDetails?.data?.id}`);
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API}/api/4c/product/info/${data.rowId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Hostname: "localhost",
            },
          },
        );

        if (!response.ok) {
          throw new Error(`Error fetching data for ID: ${data.rowId}`);
        }

        const result = await response.json();
        setProductDetails(result);
      } catch (error) {
        console.error("Error fetching product info:", error);
      }
    };

    if (data?.rowId) {
      fetchProductDetails();
    }
  }, [data, token]);

  return (
    <div className="result_item" onClick={getIteam}>
      {productDetails ? (
        <div className="result__box">
          <Image
            unoptimized
            className="search__image"
            width={32}
            height={32}
            src={
              getImageUrl(productDetails.data.imageId[0]) || "/placeholder.jpg"
            }
            alt={productDetails.title || "Product Image"}
          />
          <h2 className="search__heading">
            {productDetails.name || data.value}
          </h2>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Searchcard;
