export default function SearchSvg() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.16667 3.33332C5.94501 3.33332 3.33334 5.94499 3.33334 9.16666C3.33334 12.3883 5.94501 15 9.16667 15C12.3883 15 15 12.3883 15 9.16666C15 5.94499 12.3883 3.33332 9.16667 3.33332ZM1.66667 9.16666C1.66667 5.02452 5.02454 1.66666 9.16667 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16667 16.6667C5.02454 16.6667 1.66667 13.3088 1.66667 9.16666Z'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.3274 13.3274C13.6528 13.002 14.1805 13.002 14.5059 13.3274L18.0892 16.9108C18.4147 17.2362 18.4147 17.7638 18.0892 18.0893C17.7638 18.4147 17.2362 18.4147 16.9107 18.0893L13.3274 14.5059C13.002 14.1805 13.002 13.6529 13.3274 13.3274Z'
				fill='currentColor'
			/>
		</svg>
	)
}
