"use client";

import { AuthProvider } from "./context/authContext";
import { CartProvider } from "./context/cartContext";

export function GlobalProvider({ children }) {
  return (
    <AuthProvider>
      <CartProvider>{children}</CartProvider>
    </AuthProvider>
  );
}
