

export default function ClickLoading() {

return(
    <div className="login_loading">
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
)


}