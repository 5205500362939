"use client";
import { useContext } from "react";
import AuthContext from "../context/authContext";
import Login from "./modals/login";
import SmsCode from "./modals/smsCode";
import UserInfo from "./modals/userInfo";

export default function Modals() {
  const { showLogin, smsCode, showUserInfo } = useContext(AuthContext);
  return (
    <>
      {showLogin && <Login />}
      {smsCode && <SmsCode />}
      {showUserInfo && <UserInfo />}
    </>
  );
}
