"use client";

import { useState, useRef, useEffect } from "react";
import SearchSvg from "../../assets/frags/searchSvg";
import Cookies from "js-cookie";
import Searchcard from "./searchcard";
import { useRouter } from "next/navigation";
import { useLocale } from "next-intl";

export default function Search() {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const debounceTimeout = useRef(null);
  const routs = useRouter();
  const locale = useLocale();
  const token = Cookies.get("access_token");

  const fetchSearchResults = async (query) => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API}/api/v1/search/${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Hostname: "localhost",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Search error:", error);
      setResults([]);
    }
  };

  useEffect(() => {
    if (search.trim()) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        fetchSearchResults(search.trim());
      }, 500);
    } else {
      setResults([]);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [search]);

  return (
    <div className="search">
      <div className="wrapper">
        <input
          className="input_default"
          type="text"
          placeholder="Искать..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <button
          role="button"
          aria-label="Search"
          type="submit"
          className="search-button"
        >
          <SearchSvg />
        </button>
        <button
          onClick={() => {
            routs.push(`/${locale}/product/category/2`), setSearch("");
          }}
          className="routes"
        >
          Найти
        </button>
      </div>
      {search.length > 0 && (
        <div className="wrapper_response">
          {results?.data?.map((item, index) => (
            <Searchcard key={index} data={item} setSearch={setSearch} />
          ))}
        </div>
      )}
    </div>
  );
}
