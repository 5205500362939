"use client";

import { useLocale, useTranslations } from "next-intl";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import CloseSvg from "../../assets/frags/closeSvg";
import AuthContext from "../../context/authContext";
import ClickLoading from "../clickLoading";
import Bg from "./bg";
import { axiosInstance } from "@/lib";
import Cookies from "js-cookie";

export default function Login() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const locale = useLocale();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const { setShowLogin, setTelephone, setSmsCode, setRefresh, setToken } =
    useContext(AuthContext);

  const headers = {
    Hostname: "localhost",
    "Content-Type": "application/json",
  };

  const sendRequest = async (url, data, token = null) => {
    try {
      const response = await axiosInstance.post(url, data, {
        headers: {
          ...headers,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = Cookies.get("refresh_token");
      if (!refreshToken) throw new Error("Refresh token not found");

      const response = await sendRequest(`/api/client/auth/refresh-token`, {
        deviceType: "WEB",
        refreshToken,
      });

      if (response.status === 200 && response.data) {
        Cookies.set("access_token", response.data.access_token, {
          secure: true,
        });
        Cookies.set("refresh_token", response.data.refresh_token, {
          secure: true,
        });
        setToken(response.data.access_token);
        setRefresh(response.data.refresh_token);
        return response.data.access_token;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      setError("Session expired. Please log in again.");
      setShowLogin(true);
      return null;
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    setError(null);

    if (formData.phone.length < 12) {
      setError("Telefon raqami to'liq emas");
      setLoading(false);
      return;
    }

    const requestData = {
      phone: formData.phone,
      deviceType: "WEB",
      deviceName: navigator.userAgent,
    };

    try {
      const response = await sendRequest(`client/auth/token`, requestData);

      if (response.data.status === 0) {
        setError(response.data.message);
      } else if (response.status === 200) {
        Cookies.set("access_token", response.data.access_token, {
          secure: true,
        });
        Cookies.set("refresh_token", response.data.refresh_token, {
          secure: true,
        });
        setTelephone(formData.phone);
        setShowLogin(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          try {
            const retryResponse = await sendRequest(
              `client/auth/token`,
              requestData,
              newAccessToken,
            );
            if (retryResponse.status === 200) {
              Cookies.set("access_token", retryResponse.data.access_token, {
                secure: true,
              });
              Cookies.set("refresh_token", retryResponse.data.refresh_token, {
                secure: true,
              });
              setTelephone(formData.phone);
              setShowLogin(false);
            }
          } catch (retryError) {
            console.error("Retry failed:", retryError);
            setError("An error occurred. Please try again.");
          }
        }
      } else {
        console.error("Error during login:", error);
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login main_modal">
        <h1>Вход в аккаунт</h1>
        <span className="main_modal_info">
          Мы отправим проверочный код на введенный номер по SMS.
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="phone">Телефон</label>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: "Telefon raqamini kiriting",
              validate: (value) =>
                value.length >= 12 || "Telefon raqami to'liq emas",
            }}
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                {...field}
                country={"uz"}
                onlyCountries={["uz"]}
                masks={{ uz: "(..) ...-..-.." }}
                countryCodeEditable={false}
                specialLabel=""
                onChange={(value) => field.onChange(value)}
                inputProps={{
                  autoFocus: true,
                  ref,
                  required: true,
                }}
                className={`${errors.phone ? "phone_error" : ""} phone_input`}
              />
            )}
          />
          {errors.phone && (
            <span className="error-message text-red-500">
              {errors.phone.message}
            </span>
          )}
          {error && <span className="error-message">{error}</span>}
          <button
            type="submit"
            className="main_modal_btn"
            disabled={loading || !isValid}
          >
            {loading ? "Loading..." : "Получить код"}
          </button>
        </form>
        <span className="modal_main_offer">
          Avtotizatsiyadan o`tish orqali siz{" "}
          <b>shaxsiy ma`lumotlarni qayta ishlash siyosatiga</b> rozilik
          bildirasiz
        </span>
        <button className="close" onClick={() => setShowLogin(false)}>
          <CloseSvg />
        </button>
        {loading && <ClickLoading />}
      </div>
      <Bg state={setShowLogin} />
    </>
  );
}
